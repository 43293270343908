.fill-color {
    fill: #1792e5;
}

.container {
    width: 100% !important;
    padding: 5px 25px 5px 25px !important; 
}


.btnSpacing {
    margin: 10px 0 10px 10px !important;
}

.uploadWrap {
    position: relative !important;
    overflow: hidden !important;
    display: inline-block !important;
    pointer-events: none;
}
 
.uploadWrap input[type=file] {
    position: absolute !important;
    left: -80% !important;
    top: 6px !important;
    opacity: 0 !important;
    pointer-events: all;
    cursor: pointer;
}

.danger {
    color: red;
}

.btnLook {
    display: block !important;
    cursor: pointer !important;
}

.lastModifiedOn {
    padding: 0px 0px 10px 0px !important;
    font-size: 14px;
}

.info {
    padding-bottom: 10px !important;
}

.noFileInfo {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
}


/* Internal SCUF css do not delete this */

.ui.loader:after{
    border-color: #2e9de7 transparent!important;
}

.ui.loader:before {
    border: none !important
}