.toast-notification-wrap {
  background: none;
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.10);
  padding: 0;
}

.toast-notification {
  width: 100%;
  background: #ffffff;
  margin: 1em 0;
  border-left: 0.75rem solid;
  border-top: 0.125rem solid;
  border-right: 0.125rem solid;
  border-bottom: 0.125rem solid;
  display: flex;
}

.ui.notification{
  margin-top: 0 !important;
  margin-bottom:  0 !important;
}

.Toastify__toast-container{
  width: auto !important;
  max-width: 70%;
}

.toast-container{
  padding: 0 !important;
}

.list-content > .content > .header{
  padding: 0 !important;
}


#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 999999;
  left: 50%;
  bottom: 30px;
}


#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}