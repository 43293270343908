.error {
    background: red;
}

.downloadNewTemplate {
    cursor: pointer !important;
    font-size: 16px !important;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #313131;
    margin-bottom: 12px;
}

.subTitle {
    font-size: 14px;
    font-weight: 500;
    color: #808080;
}

.headerSpacing {
    padding: 15px 25px 15px 25px !important; 
}

.subHeadingSpacing {
    margin-top: 4px !important;
}

.btnSpacing {
    margin-top: 20px !important;
}